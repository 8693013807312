<script>
import { formatDollar } from '/~/utils/format/money'
import CheckoutSummaryItemV1 from '/~/components/checkout/summary/checkout-summary-item.vue'
import EwProductType from '/~/components/ewallet/product/ew-product-type.v2.vue'

export default {
  name: 'checkout-summary-item-v3',
  components: {
    EwProductType,
  },
  extends: CheckoutSummaryItemV1,
  props: {
    prependEstoreTitleWithPrice: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      ...CheckoutSummaryItemV1.setup?.(...arguments),
      formatDollar,
    }
  },
}
</script>

<template>
  <div class="flex items-center justify-between">
    <div v-if="image" class="mr-[15px] flex w-12 shrink-0 text-center">
      <base-image
        :src="image"
        :fit="isEstore ? 'contain' : 'cover'"
        alt="offer logo"
        class="h-full w-full"
        rounded-size="sm"
      />
    </div>

    <div class="flex-1 overflow-hidden">
      <div class="flex flex-nowrap items-center sm:items-start">
        <div class="flex-1 overflow-x-hidden pr-[72px]">
          <div class="font-bold text-eonx-neutral-800">
            {{ isEstore ? shortName : title }}
          </div>
          <div
            class="truncate text-sm leading-6 text-eonx-neutral-600 sm:mt-[5px]"
          >
            {{ item.quantity }} x
            {{ isEstore ? formatDollar(price) : shortName }}
          </div>
        </div>
      </div>
      <template v-if="loading">
        <div
          class="h-5 w-1/3 animate-pulse rounded-md bg-eonx-neutral-50 sm:mt-[5px]"
        />
      </template>
      <template v-else>
        <div v-if="isEstore">
          <ew-product-type
            class="text-xs text-eonx-neutral-600"
            :icon-size="20"
            physical-icon="v2/box-icons/bx-truck"
            physical
          >
            <template #physical>
              <div class="flex w-full justify-between text-sm">
                <span>Delivered by supplier</span>
                <span>{{ total }}</span>
              </div>
            </template>
          </ew-product-type>
        </div>
        <ew-product-type
          v-else-if="item.gift && gift"
          class="text-eonx-neutral-600"
          :gift="item.gift"
          :gift-card="!!item.retailerName"
          physical-icon="v2/box-icons/bx-truck"
          :icon-size="20"
          :physical="item.physical"
        >
          <template #digital>
            <div class="flex w-full justify-between">
              <span>Gift to {{ gift.email }}</span>
              <span>{{ total }}</span>
            </div>
          </template>
          <template #physical>
            <div class="flex w-full justify-between">
              <span>Gift to {{ gift.name }}</span>
              <span>{{ total }}</span>
            </div>
          </template>
        </ew-product-type>
        <ew-product-type
          v-else
          class="text-eonx-neutral-600"
          :gift="item.gift"
          :gift-card="!!item.retailerName"
          physical-icon="v2/box-icons/bx-truck"
          :icon-size="20"
          :physical="item.physical"
        >
          <template #digital>
            <div class="flex w-full justify-between">
              <span>
                Delivered to
                {{ ewalletLabels.yourEwallet }}
              </span>
              <span>{{ total }}</span>
            </div>
          </template>
          <template #physical>Delivered by AusPost</template>
        </ew-product-type>
      </template>
    </div>
  </div>
</template>
